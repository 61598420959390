import React, { useState, useEffect } from "react";
import videoIcon from '../../images/vdp-video-icon.png';
import style from "./vdp.module.scss"

export default function VDPVideoButton({ videoUrl }) {
    const [modalOpen, setModalOpen] = useState(false);
    const [videoType, setVideoType] = useState('');
    const [adjustedLink, setAdjustedLink] = useState('');
    const [width, setWidth] = useState('');
    const [height, setHeight] = useState('');
    const [supported, setSupported] = useState(true);
    const [isVertical, setIsVertical] = useState(false);

    const originalLink = videoUrl

    // event listener for popstate to handle navigation backwards/forwards
    useEffect(() => {
        const handlePopState = () => {
            if (modalOpen) {
                closeVideoModal();
            }
        };
    
        window.addEventListener('popstate', handlePopState);
    
        return () => {
            window.removeEventListener('popstate', handlePopState);
        };
    }, [modalOpen]);

    // gets aspect ratio of video metadata
    function getAspectRatio(videoUrl) {
        return new Promise((resolve, reject) => {
            const video = document.createElement('video');
            video.preload = 'metadata';
            video.onloadedmetadata = () => {
                window.URL.revokeObjectURL(video.src);
                const aspectRatio = video.videoWidth / video.videoHeight;
                resolve(aspectRatio);
            };
            video.onerror = reject;
    
            video.src = videoUrl;
        });
    }

   function checkVideoType(originalLink) {
        let isLocalVideo = false;
        let isCloudfareVideo = false;
        let isVideoSupported = true;
        let adjustedVideoLink = '';
        let videoWidth = '';
        let videoHeight = '';

        switch (true) {

        // check if link is local video
        case originalLink.endsWith('.mp4') || originalLink.endsWith('.webm'):
            isLocalVideo = true;
            console.log('Supported local Video:', originalLink);

            getAspectRatio(originalLink).then((aspectRatio) => {
                if (aspectRatio >= 1) {
                    // Landscape or square aspect ratio 
                    videoWidth = '996';
                    videoHeight = '560';
                } else {
                    // Portrait aspect ratio 
                    videoWidth = '316';
                    videoHeight = '560';
                    setIsVertical(true); 
                }

                setWidth(videoWidth);
                setHeight(videoHeight);
            });
                
            break;

        // check if link is YouTube short
        case originalLink.includes('youtube.com/shorts/'):
            adjustedVideoLink = originalLink.replace('youtube.com/shorts/', 'youtube.com/embed/');

            videoWidth = '316';
            videoHeight = '560';
            setIsVertical(true);

            console.log('Converted a YouTube shorts video', adjustedVideoLink)
            break;

        // check if link is YouTube watch
        case originalLink.includes('youtube.com/watch?v='):
            // replace 'watch?v=' with 'embed/' 
            adjustedVideoLink = originalLink.replace('youtube.com/watch?v=', 'youtube.com/embed/');

            videoWidth = '996';
            videoHeight = '560';
            console.log('Converted a YoutTube watch? video', adjustedVideoLink)
            break;
        

        // check if link is YouTube embed
        case originalLink.includes('youtube.com/embed/'):
            // remove 'si=' from url
            adjustedVideoLink = originalLink.replace(/(\?|\&)si=[^&]+/, '');

            videoWidth = '996';
            videoHeight = '560';
            console.log('YouTube embed link - no conversion required', adjustedVideoLink)
            break;
         

    // check if link is youtu.be
        case originalLink.includes('youtu.be'):
            // remove 'si=' from url
            adjustedVideoLink = originalLink.replace(/(\?|\&)si=[^&]+/, '');
            
            // replace 'youtu.be/' with 'youtube.com/embed/'
            adjustedVideoLink = adjustedVideoLink.replace('youtu.be/', 'youtube.com/embed/');
            videoWidth = '996';
            videoHeight = '560';
            console.log('Converted a youtu.be video', adjustedVideoLink)
            break;
            
        case originalLink.includes('cloudflarestream'):
            adjustedVideoLink = originalLink
            isCloudfareVideo = true;
            videoWidth = '996';
            videoHeight = '560';
            console.log('Converted a cloudflarestream video', adjustedVideoLink);
            break;
        // if link doesn't match specified types
        default:
            isVideoSupported = false;
            console.log('Unsupported Video Type. Supported types include .mp4, .webm, and YouTube /shorts/, /embed/, watch?, and youtu.be.', originalLink)
        }

        setVideoType(isCloudfareVideo ? 'cloudfare' : isLocalVideo ? 'local' : 'youtube');
        setAdjustedLink(adjustedVideoLink);
        setWidth(videoWidth);
        setHeight(videoHeight);
        setSupported(isVideoSupported);
    }

    useEffect(() => {
        checkVideoType(videoUrl);
    }, [videoUrl]);


    const openVideoModal = () => {
        setModalOpen(true);
        document.body.style.overflow = 'hidden';
        const element1 = document.querySelector(`.${style["normal-vdpf"]}`);
        const element2 = document.querySelector(`.${style["offers"]}`); 
        if (element1) {
            element1.style.zIndex = '600';
        }
        if (element2) {
            element2.style.display = 'none';
        }

    };

    const closeVideoModal = () => {
        setModalOpen(false);
        document.body.style.overflow = 'auto';
        const element1 = document.querySelector(`.${style["normal-vdpf"]}`);
        const element2 = document.querySelector(`.${style["offers"]}`); 
        if (element1) {
            element1.style.zIndex = 'auto';
        }    
        if (element2) {
            element2.style.display = 'flex';
        }
    };
       
    
    return (
        supported ? (
                <div>
                    <div className={style["vdp-video-btn"]}
                        onClick={openVideoModal} 
                        >
                        <div className={style["vdp-video-btn-play"]}>
                            PLAY
                            </div>
                        <img src={videoIcon} alt="Play Icon" height="12px" style={{
                            marginLeft: "15px",
                            marginRight: "25px"
                        }} />
                        <div className={style["vdp-video-btn-video"]}>
                            VIDEO
                            </div>
                    </div>
                    {modalOpen && (
                        <div className={style["vdp-video-modal-overlay"]} onClick={closeVideoModal}>
                            <div className={style["vdp-video-modal"]}>
                                <div className={style["vdp-video-close"]}>
                                    <button 
                                    onClick={closeVideoModal}
                                    className={style["vdp-video-close-btn"]}
                                    >x</button>
                                </div>
                                <div className={`${style["vdp-video-player"]} ${isVertical ? style["vertical-media-query"] : ''}`}>
                                    {videoType === 'cloudfare' ? (<iframe
                                            src={`${adjustedLink}`}
                                            title="Video Player"
                                            height={height}
                                            width={width}
                                            style={{ maxWidth: '100%' }}
                                        ></iframe>) :
                                    videoType === 'local' ? (
                                        <video autoPlay="autoplay" muted="muted" controls height={height} width={width} style={{ maxWidth: '100%', height: 'auto' }}>
                                            <source src={videoUrl} type="video/mp4" />
                                            <source src={videoUrl} type="video/webm" />
                                            Your browser does not support the video tag.
                                        </video>
                                    ) : videoType === 'youtube' && (
                                        <iframe
                                            src={`${adjustedLink}?autoplay=1&mute=1`}
                                            title="Video Player"
                                            height={height}
                                            width={width}
                                            style={{ maxWidth: '100%' }}
                                        ></iframe>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                </div> 
        ) : null
    )
};